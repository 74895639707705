<template>
  <nav
    class="navbarTop navbar"
    id="navbarBlur"
  >
    <div class="row w-100">
      <div class="col"><searchbar /></div>

      <div class="col ml-auto" style="text-align: right;">
        <deleteSelected />
        <exportSelected />
        <toggleAll />
        <plusbutton />
        <UserBar />

      </div>

    </div>




  </nav>
</template>
<script>
import {mapActions} from "vuex";
import plusbutton from "../../filter/components/plusbutton.vue";
import searchbar from "../../filter/components/searchbar.vue";
import toggleAll from "../../filter/components/toggleall.vue";
import exportSelected from "../../filter/components/exportSelected.vue";
import deleteSelected from "../../filter/components/deleteSelected.vue";
import UserBar from "@/routing/core/User/UserBar.vue";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false
    };
  },
  components: {plusbutton, searchbar, toggleAll,  UserBar, exportSelected, deleteSelected},
  methods: {
    ...mapActions(["logout"]),
  },

};
</script>
