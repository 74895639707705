<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100 show"
    id="sidenav-collapse-main"
  >


    <ul class="navbar-nav">
<!--      <li class="nav-item"  >-->
<!--        <sidenav-item-->
<!--          url="/documents"-->
<!--          :class="getRoute() === 'documents' ? 'active' : ''"-->
<!--          :navText="'Aufträge'"-->
<!--          :bicon="'fa-file-contract'"-->
<!--        >-->
<!--        </sidenav-item>-->
<!--      </li>-->
      <li class="nav-item pink">
        <sidenav-item
            url="/tasks"
            :class="getRoute() === 'tasks' ? 'active' : ''"
            :navText="'Tickets'"
            :bicon="'fa-clipboard-list-check'"
        >
        </sidenav-item>
      </li>

    </ul>

  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer bottom-0">
    <sidenav-card
    />
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";
import {mapGetters} from 'vuex';
export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {

      title: "sketch.connect",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  computed: {
    ...mapGetters(["getUser", "getMenu"])
  },
  components: {
    SidenavItem,
    SidenavCard
  },
  created() {

  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>

<style lang="scss">

$orange:  #fd7e14 !default;
.nav-category {

  width: 100%;

  .category-link {

    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-transform: uppercase;
    margin-top: 24px;
    padding-left: 0;
    padding-right: 1rem;
    color: #344767;
    font-size: 0.8rem;

    &.category-link-employees {

      border-bottom: .09rem solid #42a62b;

    }

    &.category-link-workflows {

      border-bottom: .09rem solid #7b1991;

    }

    &.category-link-accounting {

      border-bottom: .09rem solid #ff4e3d;

    }

    &.category-link-configuration {

      border-bottom: .09rem solid $orange;

    }

  }

}

</style>
