<template>
  <template v-if="modal">
    <div class="modal-header" v-if="customerActive">
      <i class="fa-duotone fa-document"></i> Dokument erstellen für
      <template v-if="customerActive.c_company ">{{ customerActive.c_company }} -</template>
      {{ customerActive.c_firstname }} {{ customerActive.c_lastname }}
      <button type="button" class="btn btn-outline-primary" style="margin-left: auto;" @click="updateDocument(true)"><i
        class="fa-duotone fa-floppy-disk"></i> Speichern
      </button>
      <button v-if="modal" type="button" class="btn-close float-end" style="margin-left: 24px;"
              @click='$store.commit("closeModal", "document");'></button>
    </div>
  </template>
  <ul class="tabNav">
    <template v-if="modal">
      <template v-for="tab in orderTabs">
        <template v-if="documentCopy && documentCopy.id">
          <li @click="activeOrderTab =tab.name"
              v-if="isContract || tab.name !== 'contract'"
              :class="(activeOrderTab === tab.name) ? 'active' : ''"><i class="fa-duotone"
                                                                        :class="tab.icon"></i>
            <br><small>{{ tab.label }}</small>
          </li>
        </template>
        <template v-else>
          <li @click="activeOrderTab =tab.name"
              v-if="isContract || tab.name !== 'contract'"
              :class="(activeOrderTab === tab.name) ? 'active' : ''"><i class="fa-duotone"
                                                                        :class="tab.icon"></i>
            <br><small>{{ tab.label }}</small>
          </li>
        </template>
      </template>
    </template>
    <template v-else>
      <template v-for="tab in orderTabs">
        <template v-if="documentCopy && documentCopy.id">
          <li @click="$router.push({name:'DocumentsTabs', params:{id:documentCopy.id,tab:tab.name}}) "
              v-if="isContract || tab.name !== 'contract'"
              :class="(activeOrderTab === tab.name) ? 'active' : ''"><i class="fa-duotone"
                                                                        :class="tab.icon"></i>
            <br><small>{{ tab.label }}</small>
          </li>
        </template>
        <template v-else>
          <li @click="$router.push({name:'DocumentsTabs', params:{id:'new',tab:tab.name}}) "
              v-if="isContract || tab.name !== 'contract'"
              :class="(activeOrderTab === tab.name) ? 'active' : ''"><i class="fa-duotone"
                                                                        :class="tab.icon"></i>
            <br><small>{{ tab.label }}</small>
          </li>
        </template>
      </template>
    </template>
  </ul>
  <div class="card">
    <h6 class="card-header">
      <template v-if="modal">
        <i class="fa-duotone fa-document"></i> Dokument erstellen für
        <template v-if="customerActive.c_company ">{{ customerActive.c_company }} -</template>
        {{ customerActive.c_firstname }} {{ customerActive.c_lastname }}
      </template>
      <template v-else>
        <i class="fa-duotone fa-file"></i> Dokument
      </template>
      <template
        v-if="documentCopy && (documentCopy.typeStrings && documentCopy.typeStrings.offer === documentCopy.d_catid) && (documentCopy.process.level === 1) && (documentCopy.doc_state === '1')">
        <div class="btn-group me-3 ml-auto" v-if="!modal">
          <button type="button" class="btn btn-outline-danger" style="margin-left: auto;" @click="denyDoc"><i
            class="fa-duotone fa-x"></i> Angebot ablehnen
          </button>
          <button type="button" class="btn btn-outline-primary btn-sm "
                  @click="acceptDoc"><i
            class="fa-duotone fa-check"></i> Angebot annehmen
          </button>
        </div>
      </template>
    </h6>

    <div class="card-body documentItem">
      <div class="docOutestWrap">
        <template v-if="customerActive && Object.keys(customerActive).length === 0 && modal">
          <div class="form-group">
            <label>Kunde wählen</label>
            <customersearch v-model="customer"/>
          </div>
        </template>
        <template v-else>
          <div class="tab-pane active" v-if="activeOrderTab === 'booking'">
            <div class="customerInfos mb-3" v-if="customerActive">
              <p class="mb-1" v-if="customerActive.infos && customerActive.infos.taxRule">Für den Kunden ist folgende
                Steuer-Regel hinterlegt:
                {{ customerActive.infos.taxRule.rule_name }} | Umsatzsteuersatz:
                {{ customerActive.infos.taxRule.rule_factor }}%</p>
              <p class="mb-1" v-if="customerActive.c_discount">Rabatt: {{ customerActive.c_discount }}%</p>
              <p class="mb-1"
                 v-if="customerActive.project_free_hours_monthly && customerActive.project_free_hours_monthly !== '0.00'">
                Freikontingent im Monat: {{ customerActive.project_free_hours_monthly }}</p>
            </div>
            <!--            <div class="productList fixedList" :class="(showList) ? 'active': ''">-->
            <!--              <div class="searchHead">-->
            <!--                <div class="row">-->
            <!--                  <div class="col-2"><span class="text-primary" @click="toggleList"><i-->
            <!--                    class="fa-duotone fa-arrow-right-from-bracket"></i></span></div>-->
            <!--                  <div class="col-10">-->
            <!--                    <div class="input-group input-group-sm">-->
            <!--                      <input type="text" class="form-control" v-model="filterProduct" @keyup="handleKeyUp"-->
            <!--                             ref="searchInput"-->
            <!--                             placeholder="Produktschnellsuche">-->
            <!--                      <div class="input-group-append" v-if="filterProduct">-->
            <!--                        <button class="btn btn-outline-secondary resetButton" type="button" @click="clearFilter"><i-->
            <!--                          class="fa-duotone fa-x"></i></button>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <perfect-scrollbar style="max-height: 50vh;">-->
            <!--                <template v-for="(product,index) in filteredList">-->
            <!--                  <div class="productItem" :class="{ 'highlighted': highlightedIndex === index }">-->
            <!--                    <ul class="productInfoBar inline" @click.stop="addProduct(product)">-->
            <!--                      <li class="arrow"><i class="fa-duotone fa-plus"></i></li>-->
            <!--                      <li class="title"><span>{{ product.p_title }}</span></li>-->
            <!--                      <li class="quantity">{{ product.p_default_quantity }}</li>-->
            <!--                      <li class="unit">{{ product.p_default_unit }}</li>-->
            <!--                      <li class="price">{{ $filters.priceEuro(product.p_default_price) }}</li>-->
            <!--                    </ul>-->
            <!--                  </div>-->
            <!--                </template>-->
            <!--              </perfect-scrollbar>-->
            <!--            </div>-->
            <div class="row">
              <div class="col-12 col-lg-12">
                <div class="inDocument">
                  <div class="row mb-3 headbox">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      <label class="text-xs">Rabattierung</label>
                      <div class="input-group input-group-sm">
                        <input type="number" readonly placeholder="Rabattierung" class="form-control" step="1" min="0"
                               max="100"
                               v-model="documentCopy.customer_discount" @change="recalcProducts()"></div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      <template v-if="getConfigs.costnumbers">
                        <label class="text-xs">Kostenstelle</label>
                        <div class="input-group input-group-sm">
                          <select v-model="documentCopy.cost">
                            <option v-for="cost in getConfigs.costNumbers" v-bind:key="cost.id">{{
                                cost.cost_name
                              }}
                            </option>
                          </select>
                        </div>
                      </template>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 align-right">
                      <div class="text-end">
                        <label class="text-xs">Preisberechnung</label>
                        <span v-if="documentCopy.use_nettobrutto === 'netto'"
                              class="fullPriceRender  align-left" tooltip="Summe"
                        ><i
                          class="fa-duotone fa-cash-register"></i> {{
                            $filters.priceEuro(documentCopy.complete_netto)
                          }} </span>
                        <span v-if="documentCopy.use_nettobrutto === 'brutto'"
                              class="fullPriceRender" tooltip="Summe"
                        ><i class="fa-duotone fa-cash-register"></i> {{
                            $filters.priceEuro(documentCopy.complete_brutto)
                          }}</span>
                        <template
                          v-if="documentCopy.discountable && documentCopy.discountable.reduces && documentCopy.customer_discount > 0">
                          <br>
                          <template v-if="documentCopy.use_nettobrutto === 'netto'">
                            <small tooltip="Ursprünglicher Preis"><i class="fa-duotone fa-money-bill-trend-up"></i>
                              {{ $filters.priceEuro(documentCopy.discountable.originalSum.netto) }}</small><br>
                            <small tooltip="Rabatt"><span><i
                              class="fa-duotone fa-money-bills"></i></span>
                              {{ $filters.priceEuro(documentCopy.discountable.reduces.netto) }}</small>
                          </template>
                          <template v-if="documentCopy.use_nettobrutto === 'brutto'">
                            <small tooltip="Ursprünglicher Preis"><i class="fa-duotone fa-money-bill-trend-up"></i>
                              {{ $filters.priceEuro(documentCopy.discountable.originalSum.brutto) }}</small><br>
                            <small tooltip="Rabatt"><span><i
                              class="fa-duotone fa-money-bills"></i></span>
                              {{ $filters.priceEuro(documentCopy.discountable.reduces.brutto) }}</small>
                          </template>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="productList">
                    <div class="productListHeadline" v-if="documentCopy.items && documentCopy.items.length">
                      <ul class="productInfoBar">
                        <li class="drag"></li>
                        <li class="title"></li>
                        <li class="quantity">Menge</li>
                        <li class="unit">Einheit</li>
                        <li class="price">Einzelpreis</li>
                        <li class="tax">Steuerregel</li>
                        <li class="final_price line"> Gesamtbetrag €
                        </li>
                        <li class="action">
                        </li>
                      </ul>
                    </div>
                    <template v-for="(element,index) in documentCopy.items">
                      <div class="productWrap" :class="(element.type) ? element.type+' '+element.css :'' "
                           v-if="documentCopy.items && documentCopy.items.length" :key="index">
                        <div class="productItem">
                          <ul class="productInfoBar inline" :class="(showEditMode === index) ? 'active': ''"
                              @click="toggleEditMode(index)">
                            <li class="type">
                              <template v-if="element.type ==='product' || !element.type"><i
                                class="fa-duotone fa-tags"></i></template>
                              <template v-else><i class="fa-duotone fa-folder"></i></template>
                            </li>
                            <li class="title">{{ element.di_title }}</li>
                            <li class="quantity" v-if="element.type ==='product' || !element.type">
                              {{ element.di_quantity }}
                            </li>
                            <li class="unit" v-if="element.type ==='product' || !element.type">{{ element.di_unit }}
                            </li>
                            <li class="price" v-if="element.type ==='product' || !element.type">
                              {{ element.di_base_price }}
                            </li>
                            <li class="tax" v-if="element.type ==='product' || !element.type">
                              {{ element.tax_group_id }}
                            </li>
                            <li class="discount" v-if="element.type ==='product' || !element.type"><span
                              class="blocklabel"
                              tooltip="Rabattierbar" disabled
                              position="left">

                                <input style="z-index: -10;" type="checkbox" readonly
                                       :checked="(element.discountable === 'discountable')"
                                       class="form-check-input"/> <i
                              class="fa-duotone fa-badge-percent"></i>
                              </span>
                            </li>
                            <li class="final_price line">
                              <template v-if="element.css ==='folder'">
                                  <span class="folderSum"
                                        tooltip="Zwischensumme">{{ formatValue(element.folderSum) }}</span>
                                <!--                                  <input type="text" v-model="element.folderSum"  readonly class="folderSum" />-->
                              </template>
                              <template v-else>
                                <input type="text" v-model="element._useVuePrice" v-if="element._useVuePrice" readonly/>
                                <input type="text" v-model="element.di_sum_price" v-else readonly/>
                              </template>
                            </li>
                            <li class="action">
                              <template v-if="showEditMode === index">
                                <span><i class="fa-duotone fa-chevrons-up"></i></span>
                              </template>
                              <template v-else>
                                  <span><i
                                    class="fa-duotone fa-chevrons-down"></i></span></template>
                              <!--                                <span @click="removeProduct(index)"><i class="fa-duotone fa-trash-can text-danger"></i></span>-->
                            </li>
                          </ul>
                          <div class="editorWrap" v-if="showEditMode === index">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <div class="description" v-html="element.di_description">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane active" v-if="activeOrderTab === 'docs'">
            <p>Die Platzhalter werden beim Speichern gefüllt.<br><br>
              Zur Verfügung stehen: ##SALUTATION## , ##TITLE## , ##INFO## , ##OWNER## , ##DOCNUMBER##, ##DATE##</p>
            <div class="form-group" v-if="documentCopy.customFields && documentCopy.customFields.document_sending">
              <label>Betreff</label>
              <input type="text" class="form-control" v-model="documentCopy.customFields.document_sending.subject"/>
            </div>
            <div class="form-group" v-if="documentCopy.customFields && documentCopy.customFields.document_sending">
              <label>Text</label>
              <Editor2 v-model="documentCopy.customFields.document_sending.body"/>
            </div>
          </div>
          <div class="tab-pane active" v-if="activeOrderTab === 'contract'">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Startzeitpunkt</label>
                  <VueDatePicker v-model="documentCopy.contractDetails.contract_start_date" model-type="yyyy-MM-dd"
                                 format="dd.MM.yyyy"
                                 placeholder="Startzeitpunkt" :auto-apply="true" :close-on-auto-apply="true" locale="de"
                                 :enable-time-picker="false" cancel-text="X" :select-text="this.$t('glboals.choose')"
                                 :teleport="true">
                  </VueDatePicker>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Vertragsdatum</label>
                  <VueDatePicker v-model="documentCopy.contractDetails.contract_sign_date" model-type="yyyy-MM-dd"
                                 format="dd.MM.yyyy"
                                 placeholder="Vertragsdatum" :auto-apply="true" :close-on-auto-apply="true" locale="de"
                                 :enable-time-picker="false" cancel-text="X" :select-text="this.$t('glboals.choose')"
                                 :teleport="true">
                  </VueDatePicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Datum der letzten Abrechnung</label>
                  <VueDatePicker v-model="documentCopy.contractDetails.first_invoice_date" model-type="yyyy-MM-dd"
                                 format="dd.MM.yyyy"
                                 placeholder="Datum der letzten Abrechnung" :auto-apply="true"
                                 :close-on-auto-apply="true"
                                 locale="de" :enable-time-picker="false" cancel-text="X"
                                 :select-text="this.$t('glboals.choose')" :teleport="true">
                  </VueDatePicker>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Datum der Kündigung</label>
                  <VueDatePicker v-model="documentCopy.contractDetails.contract_termination_date"
                                 model-type="yyyy-MM-dd"
                                 format="dd.MM.yyyy"
                                 placeholder="Datum der Kündigung" :auto-apply="true" :close-on-auto-apply="true"
                                 locale="de" :enable-time-picker="false" cancel-text="X"
                                 :select-text="this.$t('glboals.choose')" :teleport="true">
                  </VueDatePicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="mb-2">Vertragsstatus</label>
                  <div class="btn-group btn-group-yesno" role="group">
                    <input type="radio" class="btn-check" id="contract_state_0" value="accepted"
                           v-model="documentCopy.contractDetails.contract_state">
                    <label class="btn btn-outline-success" for="contract_state_0">Aktiv / Bestätigt</label>
                    <input type="radio" class="btn-check" id="contract_state_1" value="waiting"
                           v-model="documentCopy.contractDetails.contract_state">
                    <label class="btn btn-outline-secondary" for="contract_state_1">In Bearbeitung</label>
                    <input type="radio" class="btn-check" id="contract_state_2" value="terminated"
                           v-model="documentCopy.contractDetails.contract_state">
                    <label class="btn btn-outline-danger" for="contract_state_2">Gekündigt</label>
                    <input type="radio" class="btn-check" id="contract_state_3" value="died"
                           v-model="documentCopy.contractDetails.contract_state">
                    <label class="btn btn-outline-secondary" for="contract_state_3">Verstorben</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="mb-2">Auto-Verlängerung</label>
                  <div class="btn-group btn-group-yesno" role="group">
                    <input type="radio" class="btn-check" id="contract_repeating_0" value="yes"
                           v-model="documentCopy.contractDetails.contract_repeating">
                    <label class="btn btn-outline-success" for="contract_repeating_0">Ja</label>
                    <input type="radio" class="btn-check" id="contract_repeating_1" value="yearly"
                           v-model="documentCopy.contractDetails.contract_repeating">
                    <label class="btn btn-outline-secondary" for="contract_repeating_1">pro Geschäftsjahr</label>
                    <input type="radio" class="btn-check" id="contract_repeating_2" value="no"
                           v-model="documentCopy.contractDetails.contract_repeating">
                    <label class="btn btn-outline-danger" for="contract_repeating_2">Nein</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Laufzeit in Monaten</label>
                  <input type="number" v-model="documentCopy.contractDetails.contract_runtime_months"
                         class="form-control"/>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label class="mb-2">Laufzeit als Menge Nutzen?</label>
                  <div class="btn-group btn-group-yesno" role="group">
                    <input type="radio" class="btn-check" id="change_quantity_0" value="yes"
                           v-model="documentCopy.contractDetails.change_quantity">
                    <label class="btn btn-outline-success" for="change_quantity_0">Ja</label>
                    <input type="radio" class="btn-check" id="change_quantity_2" value="no"
                           v-model="documentCopy.contractDetails.change_quantity">
                    <label class="btn btn-outline-danger" for="change_quantity_2">Nein</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Vertragsende <small>(ergibt sich aus Laufzeit oder
                    Kündigung)</small></label>
                  <div class="inputPlaceholder">{{
                      $filters.formatDate(documentCopy.contractDetails.contract_end)
                    }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group"><label>Aktuelle Kündigungsfrist</label>
                  <div class="inputPlaceholder">{{
                      $filters.formatDate(documentCopy.contractDetails.next_termination_date)
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="mb-2">Rechnungserstellung</label>
                  <select class="form-select" v-model="documentCopy.contractDetails.contract_invoice_period">
                    <option value="monthly">Monatlich
                    </option>
                    <option value="quartaly">Quartalsweise
                    </option>
                    <option value="halfyearly">Halbjährlich
                    </option>
                    <option value="firstmonthofyear">Anfang des Geschäftsjahres
                    </option>
                    <option value="yearly">Jährlich
                    </option>
                    <option value="no">Keine
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Kündigungsfrist in Wochen</label>
                  <input type="number" v-model="documentCopy.contractDetails.contract_termination_weeks"
                         class="form-control"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group"><label>Custom Regeln</label><textarea
                  v-model="documentCopy.contractDetails.custom_rules"
                  cols="30"
                  rows="5"></textarea></div>
              </div>
            </div>
          </div>
          <div class="tab-pane active" v-if="activeOrderTab === 'preview'">
            <embed :src="'data:application/pdf;base64,'+pdfPreview" type="" style="min-height: 80vh; width: 100%;"
                   v-if="pdfPreview">
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import products from "../Products/Products.vue";
import Editor2 from "@/components/Editor2";
import draggable from 'vuedraggable'
import VueDatePicker from "@vuepic/vue-datepicker";
import axios from "axios";
import customersearch from "@/filter/components/customersearch";

export default {
  name: "Edit",
  props: {
    document: {
      type: Object,
    },
    modal: false,
  },
  computed: {
    ...mapGetters(["modalsList", "getEmployees", "customerActive", "productList", "getConfigs", "getUser"]),
  },
  components: {
    VueDatePicker,
    products,
    Editor2,
    draggable,
    customersearch
  },
  data() {
    return {
      filterProduct: '',
      localProducts: [],
      allProducts: [],
      customer: false,
      showList: true,
      showFolderDropdown: false,
      showEditMode: '',
      documentCopy: {},
      sortable: false,
      draggingProducts: {},
      highlightedIndex: -1,
      preparedDocument: {
        items: [],
        complete_sum: 0,
        complete_netto: 0,
        complete_brutto: 0,
        d_catid: '',
        contractDetails: {
          contract_start_date: '',
          contract_sign_date: '',
          first_invoice_date: '',
          contract_termination_date: '',
          contract_state: 'accepted',
          contract_repeating: 'yes',
          contract_runtime_months: '12',
          change_quantity: 'no',
          end: '',
          contract_termination_weeks: '',
          contract_invoice_period: 'monthly',
          custom_rules: '',
        },
        customFields: {
          document_sending: {
            subject: '',
            body: '',
          }
        },
        doc_number: '',
        doc_d_date: new Date().toISOString().slice(0, 10),
        doc_dl_date: new Date().toISOString().slice(0, 10),
        doc_pay_date: '',
        show_nettobrutto: 'netto',   // Default to "Netto"
        use_nettobrutto: 'netto',    // Default to "Netto"
        invoice_type: '1',           // Default to "Standard"
        active_mode: 'active',       // Default to "Aktiv"
        items_sorting: 'default',    // Default to "Standard"
        special_owner: '0',
        d_state: '0',
        address_id: '0',
      },
      isDragging: false,
      orderTabs: [
        {name: 'booking', label: 'Abrechnungsdaten', icon: 'fa-coin'},
        {name: 'contract', label: 'Vertragsdetails', icon: 'fa-file'},
        {name: 'preview', label: 'Vorschau', icon: 'fa-file'},
      ],
      activeOrderTab: 'booking',
      filteredList: [],
      addresses: {},
      isContract: false,
      pdfPreview: '',
    }
  },
  methods: {
    getProducts() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "product/search?offset=0&limit=0", {headers}).then((r) => {
        this.allProducts = r.data.result.data;
      });
    },
    toggleList() {
      this.showList = !this.showList;
      this.showFolderDropdown = false;
      if (this.showList) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    toggleEditMode(key) {
      if (this.showEditMode === key) {
        this.showEditMode = '';
      } else {
        this.showEditMode = key;
      }
    },
    previewPdf() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "documents/" + this.document.id + "/" + 'previewMail', {headers}).then((r) => {
        //   PDF Base64
        this.pdfPreview = r.data.result.data;
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    getAddresses(customerId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/addresses", {headers}).then((r) => {
        this.addresses = r.data.result.data;
      });
    },
    updateProducts(event) {
      this.localProducts = event;
      this.documentCopy.items = [...event];
      this.calcFullPrice();
    },
    filterProducts: function () {
      let searchword = this.filterProduct.toLowerCase();
      this.filteredList = this.allProducts.filter(function (el) {
        return el.p_title.toLowerCase().includes(searchword) ||
          el.p_desc.toLowerCase().includes(searchword);
      });
    },
    setNewDocument() {
      let today = new Date();
      let paramDays = this.getConfigs.params_values.payment_due_date;
      let dueDate = new Date(today.setDate(today.getDate() + parseInt(paramDays)));
      this.documentCopy.doc_pay_date = dueDate.toISOString().slice(0, 10);
      // set User
      this.documentCopy.d_created_author = this.getUser.id;
      if (this.customerActive && this.customerActive.c_discount) {
        this.documentCopy.customer_discount = this.customerActive.c_discount;
      } else {
        this.documentCopy.customer_discount = 0;
      }
      this.documentCopy.customFields.document_sending.subject = this.getConfigs.params_values.document_sent_subject;
      this.documentCopy.customFields.document_sending.body = this.getConfigs.params_values.document_sent;
    },
    handleKeyUp(event) {
      switch (event.key) {
        case "ArrowDown":
          // Move down the list
          if (this.highlightedIndex < this.filteredList.length - 1) {
            this.highlightedIndex++;
          }
          break;
        case "ArrowUp":
          // Move up the list
          if (this.highlightedIndex > 0) {
            this.highlightedIndex--;
          }
          break;
        case "Enter":
          // Select the highlighted product
          if (this.highlightedIndex >= 0 && this.highlightedIndex < this.filteredList.length) {
            this.addProduct(this.filteredList[this.highlightedIndex]);
          }
          break;
        case "Escape":
          // Close the list
          this.showList = false;
          this.filterProduct = '';
          break;
        default:
          this.filterProducts();
          break;
      }
    },
    addProduct: function (product) {
      const clonedProduct = JSON.parse(JSON.stringify(product));
      const useProduct = {};
      useProduct.di_title = clonedProduct.p_title;
      useProduct.di_description = clonedProduct.p_desc;
      useProduct.di_quantity = clonedProduct.p_default_quantity;
      useProduct.di_unit = clonedProduct.p_default_unit;
      useProduct.tax_group_id = clonedProduct.tax_group_id;
      useProduct.di_base_price = clonedProduct.p_base_price;
      useProduct.di_cost_number_id = clonedProduct.p_cost_number_id;
      useProduct.product_id = clonedProduct.id;
      useProduct.discountable = clonedProduct.discountable;
      useProduct.di_sum_price = clonedProduct.p_base_price * clonedProduct.p_default_quantity;
      useProduct.di_sum_price = parseFloat(useProduct.di_sum_price).toFixed(2);
      useProduct._useVuePrice = useProduct.di_sum_price;
      useProduct.type = 'product'
      useProduct.css = '';
      useProduct.di_ordering = '';
      // Check if documentCopy.items exists and has items
      if (this.documentCopy.items && this.documentCopy.items.length > 0) {
        this.documentCopy.items.push(useProduct);
      } else {
        this.localProducts.push(useProduct);
        this.documentCopy.items = [...this.localProducts];
      }
      this.calcFullPrice();
      this.$refs.searchInput.focus();
    },
    addFolder(type) {
      const useFolder = {};
      useFolder.di_title = '';
      if (type === 'spacer') {
        useFolder.di_title = 'Trenner';
      }
      if (type === 'required') {
        useFolder.di_title = 'Pflicht-Auswahl'
      }
      if (type === 'optional') {
        useFolder.di_title = "Zusatzoptionen"
      }
      useFolder.di_description = '';
      useFolder.di_base_price = 0;
      useFolder.di_quantity = 0;
      useFolder.di_unit = '';
      useFolder.tax_group_id = '';
      useFolder.di_cost_number_id = '';
      useFolder.product_id = '';
      useFolder.discountable = '';
      useFolder.di_sum_price = '0';
      useFolder.css = 'folder';
      useFolder.type = type;
      useFolder.di_ordering = '';
      useFolder.folderSum = 0;
      // Check if documentCopy.items exists and has items
      if (this.documentCopy.items && this.documentCopy.items.length > 0) {
        this.documentCopy.items.push(useFolder);
      } else {
        this.localProducts.push(useFolder);
        this.documentCopy.items = [...this.localProducts];
      }
      this.showFolderDropdown = false;
    },
    recalcProducts() {
      this.documentCopy.items.forEach((item, index) => {
        if (item.type === 'product') {
          this.calcPrice(item, index);
        }
      });
    },
    removeProduct(productIndex) {
      this.documentCopy.items.splice(productIndex, 1);
      this.calcFullPrice();
    },
    calcPrice(product, prodkey) {
      product.di_sum_price = parseFloat(product.di_base_price) * parseFloat(product.di_quantity);
      product.di_sum_price = parseFloat(product.di_sum_price).toFixed(2);
      if (product.discountable === 'discountable' && this.documentCopy.customer_discount > 0) {
        product.di_sum_price = parseFloat(product.di_sum_price) - (parseFloat(product.di_sum_price) * (parseFloat(this.documentCopy.customer_discount) / 100));
        product.di_sum_price = parseFloat(product.di_sum_price).toFixed(2);
      }
      product._useVuePrice = product.di_sum_price;
      product.di_sum_price = parseFloat(product.di_base_price) * parseFloat(product.di_quantity);
      this.documentCopy.items[prodkey] = product;
      this.calcFullPrice();
    },
    updateCustomer() {
      this.$store.dispatch('setActiveCustomer', this.customer.id);
      this.getAddresses(this.customer.id);
      if (this.customer.id) {
        this.documentCopy.c_id = this.customer.id;
      }
    },
    updateDocument(close = false) {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.documentCopy.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "document/" + VueInner.documentCopy.id, VueInner.documentCopy, {headers}).then(() => {
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Dokument wurde erfolgreich gespeichert');
          // Load Products
          VueInner.$store.dispatch('documentsFilter');
          if (close) {
            VueInner.$store.commit('closeDocument');
            VueInner.$router.push({name: 'Documents'});
          }
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "document", VueInner.documentCopy, {headers}).then(() => {
          VueInner.$store.commit('closeModal', 'document');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Dokument wurde erfolgreich angelegt');
          // Load Products
          VueInner.$store.dispatch('documentsFilter');
          this.$router.push({name: 'Documents'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    calcFullPrice() {
      let fullPrice = 0;
      let currentFolder = null;
      for (let i = 0; i < this.documentCopy.items.length; i++) {
        const item = this.documentCopy.items[i];
        const isFolder = item.css === 'folder';  // Using the css attribute for determining folder
        // If it's a new folder or the last item
        if (isFolder || i === this.documentCopy.items.length - 1) {
          if (currentFolder) {
            // Finalize the current folder's sum before moving on to the next folder
            currentFolder.folderSum = isNaN(currentFolder.folderSum) ? 0 : currentFolder.folderSum;
          }
          if (isFolder) {
            currentFolder = item;
            currentFolder.folderSum = 0;  // Reset the sum for the new folder
          }
        }
        // If it's a product, update the full price and folder sum (if there's a current folder)
        if (item.type === 'product') {
          const productSum = parseFloat(item.di_sum_price);
          if (!isNaN(productSum)) {
            fullPrice += productSum;
            if (currentFolder) {
              currentFolder.folderSum += productSum;
            }
          }
        }
      }
      this.documentCopy.complete_netto = fullPrice.toFixed(2);
      this.documentCopy.complete_brutto = (fullPrice * 1.19).toFixed(2);
      // Adjust discountable.reduces
      if (this.documentCopy.discountable && this.documentCopy.discountable.reduces) {
        this.documentCopy.discountable.reduces.netto = this.documentCopy.discountable.originalSum.netto - this.documentCopy.complete_netto;
        this.documentCopy.discountable.reduces.brutto = this.documentCopy.discountable.originalSum.brutto - this.documentCopy.complete_brutto;
      }
    },
    toggleDropdown() {
      this.showFolderDropdown = !this.showFolderDropdown
    },
    clearFilter() {
      this.filterProduct = '';
    },
    formatValue(value) {
      if (typeof value !== 'number') {
        return '0.00';
      }
      return value.toFixed(2);
    },
    acceptDoc() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.$confirm('<div class="acceptInfoBox">Um Ihre Entscheidung rechtskräftig zu dokumentieren, sind wir verpflichtet, Ihre IP-Adresse und einen aktuellen Zeitstempel zu speichern. <br>Nach dem Akzeptieren erhalten Sie eine E-Mail mit allen gespeicherten Informationen für Ihre Unterlagen.<br>Wenn Sie auf "Akzeptieren" klicken, stimmen Sie diesen Hinweisen zu. </div>', 'Angebot verbindlich annehmen', 'question', {
        confirmButtonText: "Akzeptieren",
        cancelButtonText: "Abbrechen",
        type: 'warning'
      }).then(() => {
        axios.post(this.$store.state.apiUrl + "document/" + this.documentCopy.id + "/accept", {}, {headers}).then(() => {
          this.$store.dispatch('toastSuccess', 'Dokument wurde erfolgreich akzeptiert');
          this.$store.dispatch('documentsFilter');
          this.$store.commit('closeDocument');
          this.$router.push({name: 'Documents'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log("User cancel")
      });
    },
    denyDoc() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.$confirm('<div class="acceptInfoBox">Um Ihre Entscheidung rechtskräftig zu dokumentieren, sind wir verpflichtet, Ihre IP-Adresse und einen aktuellen Zeitstempel zu speichern. <br>Nach dem Akzeptieren erhalten Sie eine E-Mail mit allen gespeicherten Informationen für Ihre Unterlagen.<br>Wenn Sie auf "Akzeptieren" klicken, stimmen Sie diesen Hinweisen zu. </div>', 'Angebot ablehnen', 'warning', {
        confirmButtonText: "Angebot ablehnen",
        cancelButtonText: "Abbrechen",
        type: 'warning'
      }).then(() => {
        axios.post(this.$store.state.apiUrl + "document/" + this.documentCopy.id + "/deny", {}, {headers}).then(() => {
          this.$store.dispatch('toastSuccess', 'Dokument wurde erfolgreich abgelehnt');
          this.$store.dispatch('documentsFilter');
          this.$store.commit('closeDocument');
          this.$router.push({name: 'Documents'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log("User cancel")
      });
    }
  },
  mounted() {
    this.getProducts();
    this.filteredList = this.productList.data;
    // if this.document is empty object use default values from data
    if (Object.keys(this.document).length > 0) {
      this.documentCopy = JSON.parse(JSON.stringify(this.document));
      if (this.documentCopy.use_nettobrutto === "netto") {
        this.documentCopy.items = this.documentCopy.items.map((item) => {
          item.di_sum_price = parseFloat(item._calcedPrices.netto).toFixed(2);
          return item;
        });
      } else {
        this.documentCopy.items = this.documentCopy.items.map((item) => {
          item.di_sum_price = parseFloat(item._calcedPrices.brutto).toFixed(2);
          return item;
        });
      }
      if (this.customerActive && this.customerActive.id) {
        this.documentCopy.c_id = this.customerActive.id;
      }
      if (this.customer.id) {
        this.documentCopy.c_id = this.customer.id;
      }
    } else {
      this.documentCopy = this.preparedDocument;
      if (this.customerActive && this.customerActive.id) {
        this.documentCopy.c_id = this.customerActive.id;
      }
      if (this.customer.id) {
        this.documentCopy.c_id = this.customer.id;
      }
      console.log("Exactly here lies the problem");
      this.setNewDocument();
    }
    if (this.documentCopy.c_id) {
      this.getAddresses(this.documentCopy.c_id);
    }
    if (this.$route.params.id !== 'new') {
      this.showList = false;
    } else {
      this.showList = true;
    }
    if (this.$route.params.tab === 'documents') {
      this.activeOrderTab = 'booking';
      this.showList = true;
    } else {
      if (this.$route.params.tab) {
        this.activeOrderTab = this.$route.params.tab;
      } else {
        this.activeOrderTab = 'booking';
      }
    }
  },
  watch: {
    $route(to) {
      this.getProducts();
      if (to.params.tab === 'documents') {
        this.activeOrderTab = 'booking';
      } else {
        if (to.params.tab) {
          this.activeOrderTab = to.params.tab;
        } else {
          this.activeOrderTab = 'booking';
        }
      }
    },
    activeOrderTab: {
      // if(this.activeOrderTab === 'preview'){
      //   this.previewPdf();
      // }
      handler: function (val, oldVal) {
        if (val === 'preview') {
          this.previewPdf();
        }
      }
    },
    customer: {
      handler: function (val, oldVal) {
        this.updateCustomer();
      },
      deep: true
    },
    documentCopy: {
      handler: function (val, oldVal) {
        this.isContract = false;
        if (val.d_catid) {
          let b;
          let a;
          for (b in this.getConfigs.categories_documents) {
            a = this.getConfigs.categories_documents[b];
            if (a.id === val.d_catid && a.isContract) {
              this.isContract = true;
            }
          }
        }
        // Check what's happening inside this watcher
      },
      deep: true
    },
    document: {
      handler: function (val, oldVal) {
        if (Object.keys(this.document).length > 0) {
          this.documentCopy = JSON.parse(JSON.stringify(this.document));
          if (this.documentCopy.use_nettobrutto === "netto") {
            this.documentCopy.items = this.documentCopy.items.map((item) => {
              item.di_sum_price = parseFloat(item._calcedPrices.netto).toFixed(2);
              return item;
            });
          } else {
            this.documentCopy.items = this.documentCopy.items.map((item) => {
              item.di_sum_price = parseFloat(item._calcedPrices.brutto).toFixed(2);
              return item;
            });
            if (this.customerActive && this.customerActive.id) {
              this.documentCopy.c_id = this.customerActive.id;
            }
            if (this.customer.id) {
              this.documentCopy.c_id = this.customer.id;
            }
          }
        } else {
          this.documentCopy = this.preparedDocument;
          if (this.customerActive && this.customerActive.id) {
            this.documentCopy.c_id = this.customerActive.id;
          }
          if (this.customer.id) {
            this.documentCopy.c_id = this.customer.id;
          }
          this.setNewDocument();
        }
      },
      deep: false
    },
  }
}
</script>
<style>
.productItem.blur {
//filter: blur(3px);
}

.dragging {
  opacity: 0.5;
}

.inputPlaceholder {
  display: block;
  border-bottom: 1px solid #333;
  background: #eee;
  padding: 12px 6px;
  cursor: not-allowed;
  min-height: 48px;
  border-radius: 7px 7px 0 0;
}
</style>
